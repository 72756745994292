import React, { FC, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { VoiceAppCall } from './Menus/VoiceAppCall';
import { HelpMenu } from './Menus/HelpMenu';
import { AppMenu } from './Menus/AppMenu';
import ProfileMenu from './Menus/ProfileMenu';

const useStyles = makeStyles({
  topNavBar: {
    marginLeft: 'auto',
    marginRight: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // gap: '16px',
    position: 'absolute',
    right: 0
  },
  tooltip: {
    fontSize: '10px',
    fontWeight: 600
    // padding: '4px 8px',
    // top: '-25px'
  }
});

export interface UserProfileType {
  color: string;
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  photo?: string;
  manager_id: number | null;
  is_migrated?: boolean | null;
}
interface TopNavBarProps {
  userProfile: UserProfileType;
  logout: () => void;
  openKnockAnalytics: () => void;
  openClawApp: () => void;
  isClawAvailable: boolean;
  openProfileAccount: () => void;
  hideAnalytics: boolean;
  hideAccountViews: boolean;
  betaSwitch: (isBetaSwitchClicked?: boolean) => void;
  isRedesignBeta: boolean;
  browserAudioNotificationsToggle: () => void;
  audioNotificationsEnabled: boolean;
  openProfileSettings: () => void;
  browserNotificationsToggle: () => void;
  desktopNotificationsEnabled: boolean;
  desktopNotificationsUnavailable: boolean;
  hideChangeUser: boolean;
  openKnockVoice: () => void;
  openAdminApp: () => void;
  memberLogin: (selectedUser: number | null) => void;
  hideSettings: boolean;
  inboundBrowserCallEnable: boolean;
  isVoiceAppStatusFeatureEnable: boolean;
  voiceAppStatus: string | undefined;
  focusOnKnockVoice: () => void;
  locationPath: string;
  isClawApplicantsPageAvailable: boolean;
}

const TopNavBar: FC<TopNavBarProps> = ({
  userProfile,
  logout,
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  openProfileAccount,
  hideAnalytics,
  hideAccountViews,
  betaSwitch,
  isRedesignBeta,
  openAdminApp,
  browserAudioNotificationsToggle,
  audioNotificationsEnabled,
  openProfileSettings,
  browserNotificationsToggle,
  desktopNotificationsEnabled,
  desktopNotificationsUnavailable,
  hideChangeUser,
  memberLogin,
  hideSettings,
  inboundBrowserCallEnable,
  isVoiceAppStatusFeatureEnable,
  voiceAppStatus,
  focusOnKnockVoice,
  openKnockVoice,
  locationPath,
  isClawApplicantsPageAvailable
}) => {
  const { topNavBar } = useStyles();
  const [isChangeUserModelOpen, setIsChangeUserModelOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const defaultImageFileName = 'profile_placeholder.png';
  let isDefaultIMGPresent =
    userProfile?.photo?.indexOf(defaultImageFileName) !== -1;
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    let userfullName = userProfile?.first_name + ' ' + userProfile?.last_name;
    setFullName(userfullName);
  }, [userProfile]);

  useEffect(() => {
    let userfullName = userProfile?.first_name + ' ' + userProfile?.last_name;
    setFullName(userfullName);
  }, [userProfile]);

  let functionOnclick = function () {
    if (isChangeUserModelOpen) {
      setOpen(false);
    } else {
      if (open) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  };

  return (
    <Box className={topNavBar}>
      <omnibar-unified-menu
        key={userProfile?.id}
        slot="unified-menu"
        user-name={fullName}
        user-image-src={!isDefaultIMGPresent ? userProfile?.photo : ''}
        onClick={functionOnclick}
        // onMouseEnter={() => settoolTipText('Account & Settings')}
        // onMouseLeave={() => settoolTipText('')}
      >
        {inboundBrowserCallEnable && (
          <VoiceAppCall
            openKnockVoice={openKnockVoice}
            isVoiceAppStatusFeatureEnable={isVoiceAppStatusFeatureEnable}
            voiceAppStatus={voiceAppStatus}
            focusOnKnockVoice={focusOnKnockVoice}
          />
        )}
        <AppMenu
          openKnockAnalytics={openKnockAnalytics}
          openClawApp={openClawApp}
          isClawAvailable={isClawAvailable}
          hideAnalytics={hideAnalytics}
          isClawApplicantsPageAvailable={isClawApplicantsPageAvailable}
          userProfile={userProfile}
        />
        <HelpMenu locationPath={locationPath} />
        <ProfileMenu
          userProfile={userProfile}
          logout={logout}
          openProfileAccount={openProfileAccount}
          hideAccountViews={hideAccountViews}
          betaSwitch={betaSwitch}
          isRedesignBeta={isRedesignBeta}
          openProfileSettings={openProfileSettings}
          browserAudioNotificationsToggle={browserAudioNotificationsToggle}
          audioNotificationsEnabled={audioNotificationsEnabled}
          browserNotificationsToggle={browserNotificationsToggle}
          desktopNotificationsEnabled={desktopNotificationsEnabled}
          desktopNotificationsUnavailable={desktopNotificationsUnavailable}
          hideChangeUser={hideChangeUser}
          memberLogin={memberLogin}
          hideSettings={hideSettings}
          open={open}
          setOpen={setOpen}
          setIsChangeUserModelOpen={setIsChangeUserModelOpen}
        />
      </omnibar-unified-menu>
    </Box>
  );
};

export default TopNavBar;
