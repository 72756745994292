export const setLocalStorage = (key: string, data: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};

export const setExpiringLocalStorage = (
  key: string,
  data: any,
  timeout: number
) => {
  const expiringValue = {
    value: data,
    config: {
      DATE: new Date(),
      TIMEOUT: timeout
    }
  };

  setLocalStorage(key, expiringValue);
};

export const getLocalStorage = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getExpiringLocalStorage = (key: string) => {
  const expiringValue = getLocalStorage(key);

  if (expiringValue) {
    const { value, config } = expiringValue;

    const currentTime = new Date();
    const redirectTimeout = new Date(config.DATE);
    const elapsedTime = currentTime.getTime() - redirectTimeout.getTime();

    if (elapsedTime < config.TIMEOUT) {
      return value;
    } else {
      window.localStorage.removeItem(key);
    }
  }
};

/*
 * Session storage functions for temporary data storage
 */

export const setSessionStorage = (key: string, data: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log(e);
  }
};

export const getSessionStorage = (key: string) => {
  try {
    const item = sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  } catch (e) {
    console.log(e);
  }
};
