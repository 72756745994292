import React from 'react';

export const FilesAndFolderIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.30008 3.35656L7.79614 1.86052C7.90948 1.74717 8.06321 1.6835 8.2235 1.6835C8.38379 1.6835 8.53752 1.74717 8.65086 1.86052L9.07842 2.28808M9.07842 2.28808L5.65873 5.70729L6.94101 6.98954L10.3607 3.57033C10.7131 3.21555 10.7131 2.64287 10.3607 2.28808C10.0061 1.93524 9.43303 1.93524 9.07842 2.28808ZM7.79614 3.57033L9.07842 4.85259M9.79048 6.61749C9.79048 8.27879 9.96521 9.21777 9.06774 10.1152L8.60235 10.5802C8.57322 10.6089 8.53392 10.625 8.49299 10.625H1.52904C1.46672 10.6249 1.41057 10.5874 1.38673 10.5298C1.36289 10.4722 1.37606 10.406 1.42009 10.3619L1.66676 10.1152C2.59795 9.18406 2.39033 8.58836 2.39033 3.63036C2.39033 2.33618 3.35564 1.64304 3.62369 1.375H5.45235M4.83403 6.53239C5.19091 6.16368 5.77673 6.1469 6.15413 6.49457C6.50154 6.87191 6.48476 7.45741 6.11631 7.81423C5.75411 8.17724 4.16267 8.48517 4.16267 8.48517C4.16267 8.48517 4.47183 6.89458 4.83403 6.53239Z"
      stroke="#292B3D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
