import React, { FC, useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import {
  makeStyles,
  Box,
  NamedColors,
  white,
  black
} from '@knockrentals/knock-shared-web';
import { createStyles, Theme } from '@material-ui/core/styles';
import { CircularProgressButton } from '../../../CircularProgressButton';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors, useCommonStyles } from '../../../commonStyles/commonStyles';
import { useLeasingTeamMembers } from 'app/services/me/leasingTeam/members/hooks';
const { inputError } = colors;
const errorColor = '#F44336';

interface UserProfileType {
  color: string;
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  photo?: string;
  manager_id: number | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    paperWidth: {
      width: '430px',
      maxHeight: '450px'
    },
    formLabel: {
      top: 20,
      left: '12px !important',
      fontWeight: 400,
      fontSize: 12
    },

    dialogContent: {
      padding: '0 16px 0 16px'
    },

    errorText: {
      color: errorColor
    },
    textInput: {
      width: '100%',
      border: 'none !important',
      color: 'black !important',
      padding: '0px',
      '& .MuiInputLabel-formControl': {
        margin: 0,
        transform: 'none',
        border: 'none !important'
      },
      '& .MuiInputBase-formControl': {
        borderColor: NamedColors.slate[300],
        margin: '0px !important'
      },
      '& .MuiTypography-root': {
        fontSize: '14px'
      },
      '& .MuiFormHelperText-root': {
        color: inputError,
        marginLeft: '4px'
      },
      '& .MuiListItem-button': {
        '&:hover': {
          backgroundColor: black,
          boxShadow: 'none'
        }
      },
      '& .MuiFilledInput-root': {
        backgroundColor: colors.inputBackground
      }
    },
    boxStyle: {
      display: 'flex',
      gap: '15px',
      justifyContent: 'flex-end'
    },

    dialogTitle: {
      padding: '0px 0px 16px 2px'
    },

    formControl: {
      width: '100%',
      marginBottom: 0,

      '& .MuiInputBase-formControl': {
        marginTop: '0 !important'
      },

      '& .MuiInputBase-root': {
        borderBottom: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        background: white,
        margin: 0,
        padding: 0
      }
    },
    chipsSection: {
      backgroundColor: `${white} !important`,
      border: 'none'
    },

    dialogActions: {
      paddingBottom: '20px',
      paddingRight: '22px'
    },
    alert: {
      backgroundColor: colors.snackbarBackground
    },
    alertIcon: {
      marginRight: '10px'
    },
    menuItemHover: {
      '&:hover': {
        backgroundColor: '#F5F6FF'
      }
    },
    menuItemSelected: {
      backgroundColor: `${colors.defaultText} !important`
    },
    cancelButton: {
      border: `1px solid ${NamedColors.denim[600]}`,
      color: NamedColors.denim[600],
      lineHeight: '24px'
    },
    chipContainer: {
      borderBottom: `1px solid ${NamedColors.slate[300]}`,
      paddingBottom: '4px',
      paddingLeft: '4px'
    },

    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'hidden'
    },

    titleText: {
      fontSize: '14px',
      fontWeight: 400,
      paddingLeft: '4px'
    },

    closeButton: {
      bottom: '8px',
      position: 'relative',
      float: 'right',
      color: colors.secondaryText
    },

    chip: {
      backgroundColor: colors.chipBackground,
      color: colors.defaultText,
      fontSize: '12px',
      marginRight: '8px',

      '&:last-child': {
        marginRight: 0
      },

      '& .MuiChip-labelSmall': {
        paddingLeft: '9px',
        paddingRight: '9px',
        paddingTop: '2px'
      }
    }
  })
);

interface ChangeUserModalProps {
  showChangeUserModal: boolean;
  closeModal: (withEffect?: boolean | undefined) => void;
  userProfile: UserProfileType;
  memberLogin: (selectedUser: number | null) => void;
  setIsChangeUserModelOpen: any;
}

const ChangeUserModal: FC<ChangeUserModalProps> = ({
  showChangeUserModal,
  closeModal,
  userProfile,
  memberLogin,
  setIsChangeUserModelOpen
}) => {
  const classes = useStyles();
  const {
    boxStyle,
    paperWidth,
    formLabel,
    textInput,
    dialogActions,
    alert,
    alertIcon,
    menuItemHover,
    dialogTitle
  } = useStyles();
  const commonClasses = useCommonStyles();
  const [selectedUser, setSelectedUser] = useState<number | null>(
    userProfile?.manager_id ?? null
  );
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string | null>(null);
  const { allLeasingTeamMembers } = useLeasingTeamMembers();
  const handleChange = (event: any) => {
    setSelectedUser(event.target.value);
    setHasValidationError(false);
  };

  const handleClose = () => {
    closeModal();
  };

  const submitHandler = async () => {
    setIsLoading(false);
    setHasValidationError(false);
    memberLogin(selectedUser ?? null);
    closeModal(true);
  };

  useEffect(() => {
    setSelectedUser(userProfile?.manager_id ?? null);
  }, [showChangeUserModal, userProfile.manager_id]);

  useEffect(() => {
    setIsChangeUserModelOpen(true);
  }, [setIsChangeUserModelOpen]);

  const handleCloseAlert = () => {
    setSnackMessage(null);
  };

  const getUserOptions = () => {
    return allLeasingTeamMembers
      ?.map((member) => ({
        label: `${member.ManagerInfo.first_name} ${member.ManagerInfo.last_name}`,
        value: member.Manager.id
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <>
      <Snackbar
        ContentProps={{
          classes: {
            root: alert
          }
        }}
        data-testid="snack-alert-message"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackMessage}
        message={snackMessage}
        action={
          <IconButton
            className={alertIcon}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Dialog
        open={showChangeUserModal}
        classes={{ paperWidthSm: paperWidth }}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogTitle className={dialogTitle}>
            Change User
            <IconButton
              data-testid="close-button"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <div className={classes.titleText}>
            Use Knock as another user on this leasing team.
          </div>
          <form className={classes.container}>
            <FormControl variant="filled" className={textInput}>
              <InputLabel id="change-owner-label" className={formLabel}>
                User
              </InputLabel>
              <Select
                labelId="change-owner-label"
                id="change-owner"
                value={selectedUser}
                onChange={handleChange}
                error={hasValidationError}
                className={textInput}
                inputProps={{ 'data-testid': 'change-user' }}
              >
                {getUserOptions().map((user, index) => (
                  <MenuItem
                    key={index}
                    value={user.value}
                    classes={{ root: menuItemHover }}
                  >
                    {user.label}
                  </MenuItem>
                ))}
              </Select>
              {hasValidationError ? (
                <FormHelperText className={classes.errorText}>
                  Please select an user
                </FormHelperText>
              ) : (
                <></>
              )}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Box className={boxStyle}>
            <CircularProgressButton
              className={commonClasses.primaryButton}
              onClick={submitHandler}
              shouldShowProgress={loading}
              progressText={'Saving...'}
            >
              Save
            </CircularProgressButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeUserModal;
