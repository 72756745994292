import React, { FC, useMemo } from 'react';

// Define an enum for page routes
// eslint-disable-next-line
enum PageRoutes {
  INBOX = '/inbox',
  DASHBOARD = '/dashboard',
  RESIDENTS = '/residents',
  PROSPECTS = '/prospects',
  CALENDAR = '/manager/calendar',
  UNITS = '/units',
  LEASING_BINDER = '/manager/properties',
  MANAGER_PROFILE = '/manager/account/profile',
  MANAGER_PREFERENCES = '/manager/account/preferences',
  GUEST_CARD = '/conversation',
  MANAGER_COMMUNITY = '/manager/community',
  MANAGER_MARKETING_RESPONDER = '/manager/marketing/responder',
  MANAGER_MARKETING_CRAIGSLIST = '/manager/marketing/craigslist',
  MANAGER_MARKETING_TEMPLATES = '/manager/marketing/templates',
  PROPERTY_FLOORPLAN = '/property/:propertyId/floorplan/:floorplanId'
}

// Define a mapping of page routes to page IDs
const pageIds: Record<PageRoutes, string> = Object.freeze({
  [PageRoutes.INBOX]: 'kncklma-inbox',
  [PageRoutes.DASHBOARD]: 'kncklma-dashboard',
  [PageRoutes.RESIDENTS]: 'kncklma-residents',
  [PageRoutes.PROSPECTS]: 'kncklma-prospects',
  [PageRoutes.CALENDAR]: 'kncklma-calendar',
  [PageRoutes.UNITS]: 'kncklma-availability',
  [PageRoutes.LEASING_BINDER]: 'kncklma-leasingBinder',
  [PageRoutes.MANAGER_PROFILE]: 'kncklma-managerProfile',
  [PageRoutes.MANAGER_PREFERENCES]: 'kncklma-managerPreferences',
  [PageRoutes.GUEST_CARD]: 'kncklma-guestCard',
  [PageRoutes.MANAGER_COMMUNITY]: 'kncklma-managerCommunity',
  [PageRoutes.MANAGER_MARKETING_RESPONDER]: 'kncklma-managerMarketingResponder',
  [PageRoutes.MANAGER_MARKETING_CRAIGSLIST]:
    'kncklma-managerMarketingCraigslist',
  [PageRoutes.MANAGER_MARKETING_TEMPLATES]: 'kncklma-managerMarketingTemplates',
  [PageRoutes.PROPERTY_FLOORPLAN]: 'kncklma-propertyFloorplan'
});

interface HelpMenuProps {
  locationPath: string;
}

export const HelpMenu: FC<HelpMenuProps> = ({ locationPath }) => {
  const pageId = useMemo(() => {
    const floorplanMatch = locationPath.match(
      /\/property\/\d+\/floorplan\/[^/]+/
    );
    if (floorplanMatch) {
      return pageIds[PageRoutes.PROPERTY_FLOORPLAN];
    }
    const pageRoute = Object.values(PageRoutes)
      .reverse()
      .find((pageRoute) => locationPath.includes(pageRoute));
    return pageRoute ? pageIds[pageRoute] : pageIds[PageRoutes.DASHBOARD];
  }, [locationPath]);
  return (
    <omnibar-unified-help
      slot="help"
      onClick={(e: any) => {
        if (e) {
          e.stopPropagation();
        }
      }}
      help-query={`pg=${pageId}&vr=40&Scrver=350`}
    ></omnibar-unified-help>
  );
};
