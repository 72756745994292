/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import indexOf from 'lodash/indexOf';
(function (angular) {
  'use strict';

  var app = angular.module('knock-MarketingTools');

  app.controller('MarketingToolsViewController', [
    '$scope',
    '$routeParams',
    '$location',
    function ($scope, $routeParams, $location) {
      var self = this;

      var tabs = ['responder', 'craigslist', 'templates'];

      self._initialize = function () {
        self._setTabIndex();

        $scope.$watch('tabIndex', function () {
          self._setRouteParam();
        });
      };

      self._setTabIndex = function () {
        $scope.tabIndex = Math.max(indexOf(tabs, $routeParams.tab), 0);
      };

      self._setRouteParam = function () {
        $location.path('/manager/marketing/' + tabs[$scope.tabIndex]);
      };
      self._initialize();
    }
  ]);
})(window.angular);
