import React, { FC, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { ThemeProvider, Divider } from '@knockrentals/knock-shared-web';
import { Provider } from 'react-redux';
import { store } from '../../../../app/store';
import { APP_CONFIG } from 'APP_CONFIG';
import LeasingTeamSelector from './LeasingTeamSelector';
import useLeasingTeamBadges from 'app/services/me/leasingTeam/Badges/hooks';
import TopNavBar from './Topbar/TopNavBar';
import InsideAngularViewportPortal from '../Portals/InsideAngularViewportPortal';

interface UserProfileType {
  color: string;
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  photo?: string;
  manager_id: number | null;
  is_migrated?: boolean | null;
}
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'omnibar-unified-help': any;
      'omnibar-shell': any;
      'omnibar-unified-menu': any;
      'omnibar-unified-switcher': any;
      'omnibar-navigation': any;
      'omnibar-user-menu-item': any;
      'omnibar-scope': any;
      'omnibar-icon': any;
      'omnibar-unified-menu-item': any;
    }
  }
  interface Window {
    OMNIBAR: any;
  }
}
export interface OmnibarLeasingTeam {
  id: number;
  name: string;
  publicId: string;
}

interface OmnibarHeaderProps {
  // currentLeasingTeam: GlobalNavLeasingTeam | null;
  headerTitle: string;
  headerSubTitle: string;
  // leasingTeams: GlobalNavLeasingTeam[];
  // leasingTeamsBadgeCounts: {
  //   [key: string]: number;
  // };
  userProfile: UserProfileType;
  userLogout: () => void;
  openKnockAnalytics: () => void;
  openClawApp: () => void;
  isClawAvailable: boolean;
  openProfileAccount: () => void;
  hideAnalytics: boolean;
  hideAccountViews: boolean;
  betaSwitch: (isBetaSwitchClicked?: boolean) => void;
  isRedesignBeta: boolean;
  // openKnockVoice: () => void;
  openAdminApp: () => void;
  openSideNavDrawer: () => void;
  browserAudioNotificationsToggle: () => void;
  audioNotificationsEnabled: boolean;
  openProfileSettings: () => void;
  browserNotificationsToggle: () => void;
  desktopNotificationsEnabled: boolean;
  desktopNotificationsUnavailable: boolean;
  hideChangeUser: boolean;
  memberLogin: (selectedUser: number | null) => void;
  hideSettings: boolean;
  inboundBrowserCallEnable: boolean;
  isVoiceAppStatusFeatureEnable: boolean;
  voiceAppStatus: string | undefined;
  focusOnKnockVoice: () => void;
  openKnockVoice: () => void;
  currentLeasingTeam: OmnibarLeasingTeam | null;
  leasingTeams: OmnibarLeasingTeam[];
  leasingTeamsBadgeCounts: {
    [key: string]: number;
  };
  locationPath: string;
  isClawApplicantsPageAvailable: boolean;
}

const productCode = APP_CONFIG.REACT_APP_RP_OMNIBAR_PRODUCT_CODE;
const environment = APP_CONFIG.REACT_APP_UNIFIED_ENVIRONMENT;
const baseUrl = APP_CONFIG.REACT_APP_WEBFRONT_BASE_URL;

const OmnibarHeader: FC<OmnibarHeaderProps> = ({
  // currentLeasingTeam,
  headerSubTitle,
  // leasingTeams,
  userLogout,
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  openProfileAccount,
  userProfile,
  hideAnalytics,
  hideAccountViews,
  betaSwitch,
  isRedesignBeta,
  openKnockVoice,
  openAdminApp,
  openSideNavDrawer,
  browserAudioNotificationsToggle,
  audioNotificationsEnabled,
  openProfileSettings,
  browserNotificationsToggle,
  desktopNotificationsEnabled,
  desktopNotificationsUnavailable,
  hideChangeUser,
  memberLogin,
  hideSettings,
  currentLeasingTeam,
  leasingTeams,
  inboundBrowserCallEnable,
  isVoiceAppStatusFeatureEnable,
  voiceAppStatus,
  focusOnKnockVoice,
  locationPath,
  isClawApplicantsPageAvailable
}) => {
  const [leasingTeamsBadgeCounts, setLeasingTeamsBadgeCounts] = useState();
  const { Badges } = useLeasingTeamBadges();

  useEffect(() => {
    setLeasingTeamsBadgeCounts(Badges);
  }, [Badges, userProfile]);
  const isUnifiedLogin = userProfile.is_migrated;

  const silentRefreshUri = isUnifiedLogin
    ? `${baseUrl}/omnibar-silent-refresh.html`
    : '';

  return (
    <omnibar-shell
      product-code={productCode}
      is-unified-login={isUnifiedLogin}
      environment={environment}
      silent-refresh-uri={silentRefreshUri}
    >
      <div
        slot="logo"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          flexShrink: 0
        }}
      >
        <img
          src="/images/nav-bar-icons/knock-logo.png"
          alt="logo"
          sizes="32px"
          aria-label="header-logo"
        />
        <Divider orientation="vertical" light style={{ height: '35px' }} />
        <Typography variant="body2" style={{ fontSize: '18px' }}>
          CRM
        </Typography>
        <LeasingTeamSelector
          currentLeasingTeam={currentLeasingTeam}
          leasingTeams={leasingTeams}
          leasingTeamsBadgeCounts={leasingTeamsBadgeCounts}
        />
        <TopNavBar
          key={userProfile.id}
          logout={userLogout}
          userProfile={userProfile}
          openKnockAnalytics={openKnockAnalytics}
          openProfileAccount={openProfileAccount}
          hideAnalytics={hideAnalytics}
          openClawApp={openClawApp}
          isClawAvailable={isClawAvailable}
          hideAccountViews={hideAccountViews}
          betaSwitch={betaSwitch}
          isRedesignBeta={isRedesignBeta}
          openKnockVoice={openKnockVoice}
          openAdminApp={openAdminApp}
          browserAudioNotificationsToggle={browserAudioNotificationsToggle}
          audioNotificationsEnabled={audioNotificationsEnabled}
          browserNotificationsToggle={browserNotificationsToggle}
          desktopNotificationsEnabled={desktopNotificationsEnabled}
          desktopNotificationsUnavailable={desktopNotificationsUnavailable}
          hideChangeUser={hideChangeUser}
          openProfileSettings={openProfileSettings}
          memberLogin={memberLogin}
          hideSettings={hideSettings}
          inboundBrowserCallEnable={inboundBrowserCallEnable}
          isVoiceAppStatusFeatureEnable={isVoiceAppStatusFeatureEnable}
          voiceAppStatus={voiceAppStatus}
          focusOnKnockVoice={focusOnKnockVoice}
          locationPath={locationPath}
          isClawApplicantsPageAvailable={isClawApplicantsPageAvailable}
        />
      </div>
    </omnibar-shell>
  );
};

export default (props: OmnibarHeaderProps) => (
  <InsideAngularViewportPortal>
    <ThemeProvider>
      <Provider store={store}>
        <OmnibarHeader {...props} />
      </Provider>
    </ThemeProvider>
  </InsideAngularViewportPortal>
);
