(function (angular) {
  var app = angular.module('knockApp');

  app.directive('viewport', function () {
    return {
      restrict: 'C',
      templateUrl: '/angular/views/navigation/viewport.html',
      controller: 'ViewportController'
    };
  });

  app.controller('ViewportController', [
    '$rootScope',
    '$route',
    'apiEvents',
    '$scope',
    '$auth',
    '$location',
    'timeService',
    'ProfileService',
    'browserNotificationsService',
    'notificationBannersService',
    'leasingTeamApi',
    'authenticationService',
    'marketingBannerService',
    'MAINTENANCE_MODE',
    'cacheKeys',
    'localStorageService',
    'appDataService',
    'LOGIN_UI_BASE_URL',
    'WEBFRONT_BASE_URL',
    'CLAW_BASE_URL',
    'ANALYTICS_BASE_URL',
    'chatTrackingService',
    'windowManagementService',
    'ADMIN_BASE_URL',
    'teamApi',
    '$window',
    '$mdToast',
    'userService',
    'pusherInstanceService',
    function (
      $rootScope,
      $route,
      apiEvents,
      $scope,
      $auth,
      $location,
      timeService,
      profileService,
      browserNotificationsService,
      notificationBannersService,
      leasingTeamApi,
      authenticationService,
      marketingBannerService,
      MAINTENANCE_MODE,
      cacheKeys,
      localStorageService,
      appDataService,
      LOGIN_UI_BASE_URL,
      WEBFRONT_BASE_URL,
      CLAW_BASE_URL,
      ANALYTICS_BASE_URL,
      chatTrackingService,
      windowManagementService,
      ADMIN_BASE_URL,
      teamApi,
      $window,
      $mdToast,
      userService,
      pusherInstanceService
    ) {
      var self = this;

      $scope.MAINTENANCE_MODE = MAINTENANCE_MODE;
      $scope.desktopNotificationsUnavailable = false;
      $scope.audioNotificationsEnabled =
        appDataService.data.preferences.audioNotificationsEnabled;

      $scope.userVoiceAppStatus =
        localStorage.getItem('voiceAppStatusOnLocalStorage') || 'away';
      $rootScope.voiceAppStatus =
        localStorage.getItem('voiceAppStatusOnLocalStorage') || 'away';

      $scope.userVoiceAppStatus = 'away';
      $rootScope.voiceAppStatus = 'away';

      $scope.browserAudioNotificationsToggle = function () {
        browserNotificationsService
          .toggleAudioNotifications()
          .then(function () {
            self.saveAudioNotificationPreference();
          })
          .catch(function (err) {
            console.log(err);
            self.saveAudioNotificationPreferenceError();
          });
      };

      self.notificationsRequestStatus = function () {
        if (!('serviceWorker' in navigator)) {
          $scope.desktopNotificationsEnabled = false;
          localStorage.setItem('DesktopNotificationEnabled', 'false');
          $scope.desktopNotificationsUnavailable = true;
        } else {
          try {
            navigator.permissions
              .query({ name: 'notifications' })
              .then(function (result) {
                if (
                  result.state === 'granted' &&
                  browserNotificationsService.getTokenSentToServer()
                ) {
                  $scope.desktopNotificationsEnabled = true;
                  localStorage.setItem('DesktopNotificationEnabled', 'true');
                } else {
                  $scope.desktopNotificationsEnabled = false;
                  localStorage.setItem('DesktopNotificationEnabled', 'false');
                }
              });
          } catch (e) {
            $scope.desktopNotificationsEnabled = false;
            localStorage.setItem('DesktopNotificationEnabled', 'false');
            console.log(e);
          }
        }
      };

      $scope.browserNotificationsToggle = function () {
        try {
          browserNotificationsService
            .toggleNotifications($scope.desktopNotificationsEnabled)
            .then(function () {
              localStorage.setItem(
                'DesktopNotificationEnabled',
                !$scope.desktopNotificationsEnabled
              );
              $scope.desktopNotificationsEnabled =
                !$scope.desktopNotificationsEnabled;
              self.saveNotificationPreference();
            })
            .catch(function (err) {
              console.log(err);
              $scope.desktopNotificationsEnabled = false;
              localStorage.setItem('DesktopNotificationEnabled', false);
              $scope.desktopNotificationsUnavailable = true;
              self.saveNotificationPreferenceError();
            });
        } catch (e) {
          console.log(e);
        }
      };

      $rootScope.$on(apiEvents.featureFlagsInitialized, function () {
        $scope.isClawAvailable =
          $rootScope.featureFlags.CLAW_AVAILABLE || false;
        $scope.isLmaNewNavigation = $rootScope.featureFlags.LMA_NEW_NAVIGATION;
        $scope.lmaOmnibar = $rootScope.featureFlags.LMA_OMNIBAR || false;
        $scope.hideSettings =
          $rootScope.appPreferences.company.hide_marketing_tools || false;
        $scope.isVoiceAppStatusEnhancementEnabled =
          $rootScope.featureFlags.LMA_VOICEAPP_STATUS_ENHANCEMENT || false;
        $scope.isCLAWApplicantsPageAvailable =
          $rootScope.featureFlags.CLAW_APPLICANTS_PAGE || false;

        if (!$scope.isCLAWApplicantsPageAvailable) {
          sessionStorage.setItem('isKnockBannerHidden', true);
          $scope.knockBannerHightAdjustment = '74px';
          $scope.isKnockBannerHidden = true;
        }
      });

      $scope.data = {
        useCookieForToken: $rootScope.featureFlags.USE_COOKIE_FOR_TOKEN || false
      };
      $scope.navigationState = {
        currentLeasingTeamId: null,
        globalNavTitle: 'Knock',
        globalNavSubtitle: 'CRM',
        leasingTeams: []
      };

      $scope.bannerViewState = {
        getNotificationsHeight: function () {
          if (
            notificationBannersService.notifications &&
            notificationBannersService.notifications.length
          ) {
            return notificationBannersService.notifications.length * 55;
          }
          return 0;
        },
        getMarketingBannerHeight: function () {
          return marketingBannerService.state.visible
            ? marketingBannerService.state.height
            : 0;
        },
        getBannersHeight: function () {
          return (
            $scope.bannerViewState.getNotificationsHeight() +
            $scope.bannerViewState.getMarketingBannerHeight() +
            'px'
          );
        }
      };

      $scope.getSessionValue = sessionStorage.getItem('isKnockBannerHidden');
      if ($scope.getSessionValue === 'true') {
        $scope.isKnockBannerHidden = true;
        $scope.knockBannerHightAdjustment = '74px';
      } else {
        $scope.isKnockBannerHidden = false;
        $scope.knockBannerHightAdjustment = '137px';
      }

      window.addEventListener('storage', (event) => {
        if (event.key === 'isKnockBannerHidden') {
          $scope.$apply(() => {
            $scope.isKnockBannerHidden = true;
            $scope.knockBannerHightAdjustment = '74px';
          });
        }
        //  else {
        //   $scope.isKnockBannerHidden = false;
        // }
      });

      $scope.closeknockNavigationBanner = function () {
        $scope.isKnockBannerHidden = true;
        $scope.knockBannerHightAdjustment = '74px';
      };

      try {
        $scope.hideNavigation = $route.current.$$route.hideNavigation || false;
      } catch (e) {
        $scope.hideNavigation = true;
      }

      try {
        $scope.useBrandedNavbar = $route.current.$$route.useBrandedNavbar;
      } catch (e) {
        $scope.useBrandedNavbar = false;
      }

      try {
        $scope.loginTopBar = $route.current.$$route.loginTopBar;
      } catch (e) {
        $scope.loginTopBar = false;
      }

      $rootScope.$on(apiEvents.profileUpdated, function () {
        self._initialize();
      });

      $rootScope.$on('$routeChangeSuccess', function () {
        // if the ?lt query parameter is not specified but a leasing team id is
        // present in session storage, add the leasing team id to the URL for
        // visible user feedback
        let queryParams = new URLSearchParams(window.location.search);
        if (
          !queryParams.get('lt') &&
          window.sessionStorage.scopedLeasingTeamId
        ) {
          $location.search('lt', window.sessionStorage.scopedLeasingTeamId);
        }
        $scope.locationPath = $location.path();
      });

      $scope.onMobile = $window.innerWidth < 428;

      $window.onresize = () => {
        $scope.$apply(() => {
          if ($window.innerWidth < 428) {
            $scope.onMobile = true;
          } else {
            $scope.onMobile = false;
            $rootScope.$emit('openSideNavDrawer', false);
          }
        });
      };

      $scope.openSideNavDrawer = () => {
        if ($scope.onMobile) {
          $rootScope.$emit('openSideNavDrawer');
        }
      };

      $rootScope.$on('$routeChangeStart', function (event, nextRoute) {
        if (nextRoute.controller !== 'ConversationPageController') {
          chatTrackingService.show();
        }

        $scope.hideNavigation = nextRoute.hideNavigation;
        $scope.useBrandedNavbar = nextRoute.useBrandedNavbar;
        $scope.loginTopBar = nextRoute.loginTopBar;

        $scope.isLoggedIn = $auth.isAuthenticated();

        if (
          authenticationService.getUserType() === 'manager' &&
          !$scope.hasTimezone
        ) {
          $scope.isManager = true;
          self._getProfile();
        }
      });

      $rootScope.$on(apiEvents.appDataInitialized, () => {
        self._isMultiteamUser();
        self._getVoiceAppStatus();
      });

      self._initialize = function () {
        timeService._activeTimezone = null;
        self.notificationsRequestStatus();
        $scope.knockBannerHightAdjustment = '137px';

        $scope.isLoggedIn = $auth.isAuthenticated();

        if ($scope.isLoggedIn) {
          self._getProfile();
        }
        if ($scope.isVoiceAppStatusEnhancementEnabled) {
          self._getVoiceAppStatus();
        }

        $scope.getSessionValue = sessionStorage.getItem('isKnockBannerHidden');
        if ($scope.getSessionValue === 'true') {
          $scope.isKnockBannerHidden = true;
          $scope.knockBannerHightAdjustment = '74px';
        } else {
          $scope.isKnockBannerHidden = false;
          $scope.knockBannerHightAdjustment = '137px';
        }
      };

      self._mapGlobalNavLeasingTeam = (leasingTeam) => {
        return {
          name: leasingTeam.name,
          publicId: leasingTeam.public_id,
          id: leasingTeam.id
        };
      };
      self._getVoiceAppStatus = function () {
        self._getVoiceAppStatusDefault();
        const user = userService.getUser();
        if (user) {
          const pusherClient = pusherInstanceService.getInstance();
          pusherClient.subscribe(`voice-app-user-status-${user.id}`);
          pusherClient.bind('voice-app-user-status', self._voiceAppUserStatus);
        }
      };
      self._getVoiceAppStatusDefault = function () {
        // console.log(localStorage.getItem('voiceAppStatusOnLocalStorage'));
        $scope.userVoiceAppStatus =
          localStorage.getItem('voiceAppStatusOnLocalStorage') || 'away';
        $rootScope.voiceAppStatus =
          localStorage.getItem('voiceAppStatusOnLocalStorage') || 'away';
      };
      self._voiceAppUserStatus = function (voiceAppStatus) {
        localStorage.setItem(
          'voiceAppStatusOnLocalStorage',
          voiceAppStatus.status
        );

        const user = userService.getUser();
        if (user) {
          const pusherClient = pusherInstanceService.getInstance();
          pusherClient.subscribe(`voice-app-user-status-${user.id}`);
          pusherClient.bind('voice-app-user-status', self._voiceAppUserStatus);
        }
      };
      self._voiceAppUserStatus = function (voiceAppStatus) {
        $scope.userVoiceAppStatus = voiceAppStatus.status;
        $rootScope.voiceAppStatus = voiceAppStatus.status;
      };
      self._setMultiteamLeasingTeams = function () {
        if ($scope.isMultiteam && $scope.leasingTeams) {
          $scope.navigationState.currentLeasingTeamId =
            appDataService.getCurrentLeasingTeamId();

          const mappedLeasingTeams = $scope.leasingTeams.map((team) => {
            if (team.id === $scope.navigationState.currentLeasingTeamId) {
              $scope.navigationState.currentLeasingTeam =
                self._mapGlobalNavLeasingTeam(team);
            }

            return self._mapGlobalNavLeasingTeam(team);
          });

          $scope.navigationState.leasingTeams = mappedLeasingTeams.sort(
            (a, b) => (a.name <= b.name ? -1 : 1)
          );
        } else {
          $scope.navigationState.leasingTeams = $scope.leasingTeams;
          if ($scope.leasingTeams && $scope.leasingTeams[0]) {
            $scope.navigationState.currentLeasingTeam =
              self._mapGlobalNavLeasingTeam($scope.leasingTeams[0]);
          }
        }
      };
      $scope.logout = function () {
        if (localStorage.getItem('voiceAppStatusOnLocalStorage')) {
          if (localStorage.getItem('voiceAppStatusOnLocalStorage') !== 'away') {
            windowManagementService.openKnockVoice(
              undefined,
              undefined,
              $scope.data.useCookieForToken
            );
          }
          localStorage.removeItem('voiceAppStatusOnLocalStorage');
        }
        localStorage.setItem('voiceAppStatusOnLocalStorage', 'away');
        sessionStorage.setItem('isKnockBannerHidden', false);
        $scope.knockBannerHightAdjustment = '74px';
        $auth.logout();
        window.location.assign(
          `${LOGIN_UI_BASE_URL}/logout?redirect_url=${WEBFRONT_BASE_URL}/login`
        );
      };

      $scope.openKnockAnalytics = function () {
        windowManagementService.openLinkWithShortToken(
          `${ANALYTICS_BASE_URL}/login`,
          'accessToken',
          '_blank',
          undefined,
          $scope.data.useCookieForToken
        );
      };

      $rootScope.$on('open-analytics', () => {
        $scope.openKnockAnalytics();
      });

      $scope.openClawApp = function () {
        windowManagementService.openLinkWithShortToken(
          `${CLAW_BASE_URL}/autologin`,
          'token',
          '_blank',
          undefined,
          $scope.data.useCookieForToken
        );
      };

      $scope.openAdminApp = function () {
        window.open(`${ADMIN_BASE_URL}`, '_blank');
      };

      $scope.openKnockVoice = function (streamId) {
        if ($scope.isVoiceAppStatusEnhancementEnabled) {
          const status = $rootScope.voiceAppStatus;
          windowManagementService.openKnockVoice(
            streamId,
            status,
            $scope.data.useCookieForToken
          );
        } else {
          windowManagementService.openKnockVoice(
            streamId,
            undefined,
            $scope.data.useCookieForToken
          );
        }
      };

      $scope.focusOnKnockVoice = function () {
        windowManagementService.focusOnKnockVoice();
      };

      $scope.openProfileAccount = function () {
        $scope.$apply(() => {
          $location.path('/manager/account/profile');
        });
      };

      $scope.openProfileSettings = function () {
        $scope.$apply(() => {
          $location.path('/manager/marketing/responder');
        });
      };

      $scope.betaSwitchOpen = function (isBetaSwitchClicked) {
        localStorage.setItem('ProspectResidentBeta', isBetaSwitchClicked);

        if (isBetaSwitchClicked) {
          if ($location.$$path === '/reports/prospects') {
            $scope.$apply(() => {
              $location.path('/prospects');
            });
          } else if ($location.$$path === '/reports/residents') {
            $scope.$apply(() => {
              $location.path('/residents');
            });
          }
        } else {
          if ($location.$$path === '/prospects') {
            $scope.$apply(() => {
              $location.path('/reports/prospects');
            });
          } else if ($location.$$path === '/residents') {
            $scope.$apply(() => {
              $location.path('/reports/residents');
            });
          }
        }
      };

      $scope.memberLogin = function (manager_id) {
        if (manager_id === $scope.profile.manager_id) {
          return;
        } else {
          teamApi
            .memberLogin(manager_id)
            .success(function (res) {
              authenticationService.loginWithToken(res.token);
            })
            .error(function () {
              $scope.memberLoginError =
                'unable to login, please try again later';
            });
        }
      };

      $scope.switchLeasingTeam = function (leasingTeamPublicId, leasingTeamId) {
        if (leasingTeamPublicId) {
          window.open(`/dashboard?lt=${leasingTeamPublicId}`, '_blank');
          return;
        }

        leasingTeamApi
          .switchLeasingTeam(leasingTeamId)
          .then((res) => {
            localStorageService.set(
              cacheKeys.refreshToken,
              res.data.refresh_token
            );
            authenticationService.loginWithToken(res.data.token);
            window.location.href = '/dashboard';
          })
          .catch(function () {
            $scope.memberLoginError = 'Unable to switch leasing teams.';
          });
      };

      self._getProfile = function () {
        var userType = authenticationService.getUserType();

        $scope.isManager = userType === 'manager';

        return profileService
          .getProfile(true)
          .success(function (response) {
            $scope.profile = response.profile;
            $scope.isRenter = $scope.profile && userType === 'renter';
            $scope.isManager = $scope.profile && userType === 'manager';

            if ($scope.isManager) {
              self._isMultiteamUser();
              self._getSubscription();
              self._getMembership();
              self._getTimezone();
            }
          })
          .error(function (response) {
            var authPayload = $auth.getPayload();

            if (authPayload) {
              if (
                authPayload.sub.type === 'manager' &&
                authPayload.sub.role !== 'agent'
              ) {
                authenticationService.authenticationFailure();
                return;
              }
            }

            if (response !== null) {
              authenticationService.authenticationFailure();
            }
          });
      };

      self._isMultiteamUser = () => {
        $scope.leasingTeams = appDataService.getLeasingTeams();
        $scope.isMultiteam = appDataService.isMultiteamUser();
        self._setMultiteamLeasingTeams();
      };

      self._getSubscription = function () {
        $scope.isSubscriptionOwner = true;
      };
      self._getMembership = function () {
        // TODO IDENTITY
        $scope.isTeamAdmin = true;
      };

      self._getTimezone = function () {
        timeService._activeTimezone = null;

        return timeService.getActiveTimezone().then(function () {
          $scope.hasTimezone = true;
        });
      };

      self.saveAudioNotificationPreference = function () {
        $mdToast.show(
          $mdToast
            .simple()
            .content(
              appDataService.data.preferences.audioNotificationsEnabled
                ? 'Audio notifications on'
                : 'Audio notifications off'
            )
            .position('top right')
        );
        localStorage.setItem(
          'AudioNotificationsEnabled',
          appDataService.data.preferences.audioNotificationsEnabled
        );
        $scope.audioNotificationsEnabled = localStorage.getItem(
          'AudioNotificationsEnabled'
        );
      };

      self.saveAudioNotificationPreferenceError = function () {
        $mdToast.show(
          $mdToast
            .simple()
            .content('Notifications setting could not be saved')
            .position('top right')
        );
      };

      self.saveNotificationPreference = function () {
        $mdToast.show(
          $mdToast
            .simple()
            .content(
              $scope.desktopNotificationsEnabled
                ? 'Notifications on'
                : 'Notifications off'
            )
            .position('top right')
        );
      };

      self.saveNotificationPreferenceError = function () {
        $mdToast.show(
          $mdToast
            .simple()
            .content('Notifications setting could not be saved')
            .position('top right')
        );
      };

      self._initialize();
    }
  ]);
})(window.angular);
