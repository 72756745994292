import React from 'react';

export const CheckCircleIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.53333 6.50325L4.88604 8.12384L8.46667 3.8742M6 10.625C8.55432 10.625 10.625 8.55432 10.625 6C10.625 3.44568 8.55432 1.375 6 1.375C3.44568 1.375 1.375 3.44568 1.375 6C1.375 8.55432 3.44568 10.625 6 10.625Z"
      stroke="#292B3D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
