/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import indexOf from 'lodash/indexOf';
(function (angular) {
  'use strict';

  var app = angular.module('knock-Account');

  app.controller('AccountViewController', [
    '$rootScope',
    '$scope',
    '$routeParams',
    '$location',
    function ($rootScope, $scope, $routeParams, $location) {
      var self = this;

      var tabs = ['profile', 'preferences', 'billing'];

      self._initialize = function () {
        $scope.hidePreferencesTab =
          $rootScope.appPreferences.company.hide_account_preferences;

        self._setTabIndex();

        $scope.$watch('tabIndex', function () {
          self._setRouteParam();
        });
      };

      self._setTabIndex = function () {
        $scope.tabIndex = Math.max(indexOf(tabs, $routeParams.tab), 0);
      };

      self._setRouteParam = function () {
        $location.path('/manager/account/' + tabs[$scope.tabIndex]);
      };

      self._initialize();
    }
  ]);
})(window.angular);
