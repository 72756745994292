import React, { FC, useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import {
  Box,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { AngularContextProvider } from '../AngularContextProvider';
import { store } from '../../../../app/store';
import Prospects from './Prospects';
import { Prospect } from '../../../../app/services/prospects/entities';
import { FilterOption } from '../../../../app/features/prospects/filter';
import { ManagerInfo } from '../QuickReplies/models';

const useStyles = makeStyles(() => ({
  mainContainer: {
    backgroundColor: NamedColors.slate[50],
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

interface TimeOption {
  label: string;
  value: string;
}

interface ProspectsPageProps {
  properties: any[];
  sources: any[];
  managers: any[];
  referralsEnabled: boolean;
  mergeTags: [];
  token: string;
  openProspectStreamModal: (threadId: string) => void;
  getManagerQuickReplies: () => Promise<any>;
  saveQuickReply: (managerId: number, payload: any) => void;
  deleteQuickReply: (
    managerId: number,
    messageId: string,
    useDeleteEndpoint: boolean
  ) => void;
  managerInfo: ManagerInfo;
  isCompanyQuickRepliesEnabled: boolean;
  handleAttachments: (
    files: any,
    uploadSuccess: any,
    uploadProgress: any,
    uploadError: any
  ) => void;
  deleteAttachment: (deleteToken: string) => Promise<any>;
  sendBatchMessage: (
    streamIds: [],
    messageText: string | null,
    messageHtml: string,
    messageSubject: string,
    targets: [],
    attachments: any,
    asManagerId: string | null
  ) => Promise<any>;
  openCarousel: (threadIds: string[]) => void;
  cheatProofEngagementScoreEnabled: boolean;
  timeService: any;
  removeQueryStringParam: (paramName: string) => void;
  currentCompanyId: number;
  isDemandXEnabled: boolean;
  isDemandXPriorityEnabled: boolean;
  isDemandXDemoEnabled: boolean;
  isInternal: boolean;
  emailDomainBlocklist: string[];
  isBulkCommunicationEnhancementEnabled: boolean;
  isAgentAttributionEnable: boolean;
  generateTimeOptions: (date: Date) => TimeOption[];
  saveWalkinDetails: (data: any) => Promise<any>;
  openShownUnits: (prospect: Prospect, visitInfo: any) => void;
}

const ProspectsPage: FC<ProspectsPageProps> = ({
  properties,
  managers,
  sources,
  referralsEnabled,
  openProspectStreamModal,
  token,
  managerInfo,
  mergeTags,
  isCompanyQuickRepliesEnabled,
  getManagerQuickReplies,
  saveQuickReply,
  deleteQuickReply,
  handleAttachments,
  deleteAttachment,
  sendBatchMessage,
  openCarousel,
  cheatProofEngagementScoreEnabled,
  timeService,
  removeQueryStringParam,
  currentCompanyId,
  isDemandXEnabled,
  isDemandXPriorityEnabled,
  isDemandXDemoEnabled,
  isInternal,
  emailDomainBlocklist,
  isBulkCommunicationEnhancementEnabled,
  isAgentAttributionEnable,
  generateTimeOptions,
  saveWalkinDetails,
  openShownUnits
}) => {
  const classes = useStyles();
  const [propertyFilters, setPropertyFilters] = useState<FilterOption[]>([]);
  const [managerFilters, setManagerFilters] = useState<FilterOption[]>([]);

  const removeQueryParamFilters = () => {
    if (window.location.search.indexOf('f_prospectIds') > -1) {
      removeQueryStringParam('f_prospectIds');
    }

    if (window.location.search.indexOf('batchId') > -1) {
      removeQueryStringParam('batchId');
    }
  };

  useEffect(() => {
    const sortedProperties = properties.sort((lValue: any, rValue: any) =>
      lValue.Property.data.location.name.localeCompare(
        rValue.Property.data.location.name
      )
    );

    const sortedManagers = managers.sort((lValue: any, rValue: any) =>
      lValue.ManagerInfo.first_name.localeCompare(rValue.ManagerInfo.first_name)
    );

    setPropertyFilters(
      sortedProperties.map((property: any, index: number) => ({
        label: property.Property.data.location.name,
        value: `${property.Property.data.location.name}_${property.Property.id}`,
        index: index++,
        id: property.Property.id
      }))
    );

    setManagerFilters(
      sortedManagers.map((manager: any, index: number) => {
        return {
          label: `${manager.ManagerInfo.first_name} ${manager.ManagerInfo.last_name}`,
          value: `${manager.ManagerInfo.first_name} ${manager.ManagerInfo.last_name}_${manager.Manager.id}`,
          index: index++,
          id: manager.Manager.id
        };
      })
    );
  }, [properties, managers]);

  return (
    <AngularContextProvider
      context={{
        properties,
        managers,
        sources,
        propertyFilters,
        managerFilters,
        referralsEnabled,
        openProspectStreamModal,
        token,
        managerInfo,
        isCompanyQuickRepliesEnabled,
        mergeTags,
        getManagerQuickReplies,
        saveQuickReply,
        deleteQuickReply,
        handleAttachments,
        deleteAttachment,
        sendBatchMessage,
        openCarousel,
        cheatProofEngagementScoreEnabled,
        timeService,
        removeQueryParamFilters,
        currentCompanyId,
        isDemandXEnabled,
        isDemandXPriorityEnabled,
        isDemandXDemoEnabled,
        isInternal,
        emailDomainBlocklist,
        isBulkCommunicationEnhancementEnabled,
        isAgentAttributionEnable,
        generateTimeOptions,
        saveWalkinDetails,
        openShownUnits
      }}
    >
      <Provider store={store}>
        <ThemeProvider>
          <Box className={classes.mainContainer}>
            <Prospects />
          </Box>
        </ThemeProvider>
      </Provider>
    </AngularContextProvider>
  );
};

export default ProspectsPage;
