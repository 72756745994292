import React, { FC, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../../../app/store';
import { APP_CONFIG } from 'APP_CONFIG';
import { makeStyles, Typography, Switch } from '@material-ui/core';
import { ThemeProvider, Box, IconButton } from '@knockrentals/knock-shared-web';
import { getAuthToken } from 'LegacyAngularApp/legacy-angular-app/services/tokenService';
import {
  getSessionStorage,
  setSessionStorage
} from './localStorageForInterApp';
import {
  CheckCircleIcon,
  FilesAndFolderIcon,
  SynchronizeArrowsNewIcon,
  InterAppCloseIcon,
  BetaInterAppIcon
} from '../../components/icons';

interface KnockNavigationBannerProps {
  closeKnockNavigationBanner: any;
}

const useStyles = makeStyles({
  KnockNavigationBannerClass: {
    position: 'absolute',
    top: 42,
    zIndex: 20,
    left: '0',
    right: '0',
    height: '55px',
    '& .offset-for-sidenav': { top: '0' },
    '& .MuiAppBar-root': {
      backgroundColor: '#FFFFFF',
      flexDirection: 'unset',
      boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 5px'
    },
    '& .global-nav-header-app-bar': { minHeight: '100%', maxHeight: '100%' },
    '& .MuiFormControl-root': { margin: '0' },
    '& .MuiToolbar-root': { paddingLeft: '0px' },
    display: 'flex',
    paddingLeft: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    backgroundColor: 'white',
    flexShrink: 0,
    marginTop: '20px'
    // borderTop: '1px solid #c0c0c0'
  },
  newKnockBetaClass: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19.88px',
    letterSpacing: '0.25px',
    color: '#292B3D',
    display: 'flex'
  },
  commandHeaderLine: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#292B3D',
    display: 'flex',
    gap: '14px',
    marginTop: '2px'
  },
  closeButton: {
    color: 'black',
    marginRight: '25px',
    gap: '16px',
    display: 'flex',
    marginBottom: '2px'
  },
  firstBox: {
    display: 'flex',
    gap: '17px'
  },
  tryTheNewKnockClass: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flexShrink: 0
  },
  betaButtonClass: {
    marginRight: '6px',
    marginTop: '4px'
  },
  tryTheNewKnockTextClass: {
    marginLeft: '2px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19.88px',
    letterSpacing: '0.25px'
  },
  switchClassStyle: {
    marginRight: '16px'
  }
});

const KnockNavigationBanner: FC<KnockNavigationBannerProps> = ({
  closeKnockNavigationBanner
}) => {
  const {
    KnockNavigationBannerClass,
    newKnockBetaClass,
    closeButton,
    commandHeaderLine,
    firstBox,
    tryTheNewKnockClass,
    betaButtonClass,
    tryTheNewKnockTextClass,
    switchClassStyle
  } = useStyles();

  const [isHidden, setIsHidden] = useState<boolean>(
    !!getSessionStorage('isKnockBannerHidden')
  );

  if (isHidden) {
    return null;
  }

  return (
    <>
      <Box className={KnockNavigationBannerClass}>
        <Box className={firstBox}>
          <Box className={newKnockBetaClass}>New Knock Beta!</Box>
          <Box className={commandHeaderLine}>
            <CheckCircleIcon />
            All your leasing teams combined
          </Box>
          <Box className={commandHeaderLine}>
            <FilesAndFolderIcon />
            Application specific features
          </Box>
          <Box className={commandHeaderLine}>
            <SynchronizeArrowsNewIcon />
            Updated workflows and enhancements
          </Box>
        </Box>
        <Box className={tryTheNewKnockClass}>
          <Box className={betaButtonClass}>
            <BetaInterAppIcon />
          </Box>
          <div className={tryTheNewKnockTextClass}>
            <Typography variant="body2">Try the new Knock</Typography>
          </div>
          <div className={switchClassStyle}>
            <Switch
              onChange={() => {
                const currentPathName = window.location.pathname;

                let targetPathName = '/dashboard';

                if (['/residents', '/prospects'].includes(currentPathName)) {
                  targetPathName = currentPathName;
                } else if (currentPathName === '/manager/calendar') {
                  targetPathName = '/centralized-calendar';
                }
                const targetUrl = `${
                  APP_CONFIG.CLAW_BASE_URL
                }${targetPathName}?accessToken=${getAuthToken()}`;

                window.open(targetUrl, '_self');
              }}
            />
          </div>

          <IconButton
            size="medium"
            aria-label="Close New Knock Beta Banner"
            className={closeButton}
            onClick={() => {
              setTimeout(function () {
                setIsHidden(true);
              }, 1);
              setSessionStorage('isKnockBannerHidden', true);
              window.dispatchEvent(
                new StorageEvent('storage', { key: 'isKnockBannerHidden' })
              );
              closeKnockNavigationBanner();
            }}
          >
            <div>
              <InterAppCloseIcon />
            </div>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default (props: KnockNavigationBannerProps) => (
  <ThemeProvider>
    <Provider store={store}>
      <KnockNavigationBanner {...props} />
    </Provider>
  </ThemeProvider>
);
