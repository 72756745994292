import React, { FC, useState, useEffect } from 'react';

interface ScopeItem {
  text: string;
  value: string;
}

interface LeasingTeamSelectorProps {
  currentLeasingTeam: any;
  leasingTeams: any[];
  leasingTeamsBadgeCounts: any;
}

const LeasingTeamSelector: FC<LeasingTeamSelectorProps> = ({
  currentLeasingTeam,
  leasingTeams,
  leasingTeamsBadgeCounts
}) => {
  const [currentPage, setCurrentPage] = useState(true);

  useEffect(() => {
    const handleScopeChange = (e: Event) => {
      console.log(e);
      const customEvent = e as CustomEvent;
      const teamId = customEvent.detail.value;
      setCurrentPage(!currentPage);
      window.open('/dashboard?lt=' + teamId, '_blank');
    };

    const scopeElementRef = document.querySelector(
      'omnibar-scope'
    ) as HTMLElement;

    if (scopeElementRef) {
      const formattedLeasingTeams = leasingTeams.map((team) => {
        const badgeCount = leasingTeamsBadgeCounts?.[team.publicId] || 0;
        const displayBadgeCount = badgeCount > 0 ? `(${badgeCount})` : '';
        const scopeItem: ScopeItem = {
          text: `${team.name} ${displayBadgeCount}`,
          value: team.publicId
        };
        return scopeItem;
      });

      (scopeElementRef as HTMLElement & { items: ScopeItem[] }).items =
        formattedLeasingTeams;
    }
    scopeElementRef.addEventListener('scopeChange', handleScopeChange);
    return () =>
      scopeElementRef.removeEventListener('scopeChange', handleScopeChange);
  }, [leasingTeams, leasingTeamsBadgeCounts, currentLeasingTeam, currentPage]);

  useEffect(() => {
    const addStyle = () => {
      const elements = document.querySelectorAll('.o-option');
      elements.forEach((element) => {
        if (element.textContent) {
          const teamName = element.textContent.split('(')[0].trim();
          if (teamName === currentLeasingTeam.name) {
            element.classList.add('o-selectedTeam');
            const childClassName = element.querySelector('.o-option__text');
            if (childClassName) {
              childClassName.classList.add('o-disableText');
            }
          }
        }
      });
    };
    //This CSS will be apply after DOM is fully loaded.
    requestAnimationFrame(addStyle);
  });
  return (
    <omnibar-scope
      slot="scope"
      product={currentLeasingTeam.name}
      selector
    ></omnibar-scope>
  );
};
export default LeasingTeamSelector;
