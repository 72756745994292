import React, { FC, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
  Box,
  makeStyles,
  NamedColors,
  ThemeProvider
} from '@knockrentals/knock-shared-web';
import { store } from '../../../../app/store';
import { AngularContextProvider } from '../AngularContextProvider';
import Residents from './Residents';
import { ManagerInfo } from '../QuickReplies/models';
import { FilterOption } from '../../../../app/features/prospects/filter';

const useStyles = makeStyles(() => ({
  mainContainer: {
    backgroundColor: NamedColors.slate[50],
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));

interface ResidentsPageProps {
  properties: any[];
  managers: any[];
  mergeTags: [];
  token: string;
  cheatProofEngagementScoreEnabled: boolean;
  getManagerQuickReplies: () => Promise<any>;
  saveQuickReply: (managerId: number, payload: any) => void;
  deleteQuickReply: (
    managerId: number,
    messageId: string,
    useDeleteEndpoint: boolean
  ) => void;
  managerInfo: ManagerInfo;
  isCompanyQuickRepliesEnabled: boolean;
  handleAttachments: (
    files: any,
    uploadSuccess: any,
    uploadProgress: any,
    uploadError: any
  ) => void;
  deleteAttachment: (deleteToken: string) => Promise<any>;
  sendBatchMessage: (
    streamIds: [],
    messageText: string | null,
    messageHtml: string,
    messageSubject: string,
    targets: [],
    attachments: any,
    asManagerId: string | null
  ) => Promise<any>;
  openCarousel: (threadIds: string[]) => void;
  allowCreateResident: boolean;
  currentUserId: number;
  validateEmail: (email: string) => boolean;
  openResidentStreamModal: (streamId: string) => void;
  timeService: any;
  removeQueryStringParam: (paramName: string) => void;
  emailDomainBlocklist: string[];
  isBulkCommunicationEnhancementEnabled: boolean;
  isAgentAttributionEnable: boolean;
}

const ResidentsPage: FC<ResidentsPageProps> = ({
  properties,
  managers,
  token,
  managerInfo,
  mergeTags,
  isCompanyQuickRepliesEnabled,
  getManagerQuickReplies,
  saveQuickReply,
  deleteQuickReply,
  handleAttachments,
  deleteAttachment,
  sendBatchMessage,
  openCarousel,
  allowCreateResident,
  currentUserId,
  validateEmail,
  openResidentStreamModal,
  timeService,
  cheatProofEngagementScoreEnabled,
  removeQueryStringParam,
  emailDomainBlocklist,
  isBulkCommunicationEnhancementEnabled,
  isAgentAttributionEnable
}) => {
  const classes = useStyles();
  const [propertyFilters, setPropertyFilters] = useState<FilterOption[]>([]);

  useEffect(() => {
    const sortedProperties = properties.sort((lValue: any, rValue: any) =>
      lValue.Property.data.location.name.localeCompare(
        rValue.Property.data.location.name
      )
    );

    setPropertyFilters(
      sortedProperties.map((property: any, index: number) => ({
        label: property.Property.data.location.name,
        value: property.Property.id,
        index: index++
      }))
    );
  }, [properties]);

  return (
    <AngularContextProvider
      context={{
        properties,
        managers,
        propertyFilters,
        token,
        managerInfo,
        isCompanyQuickRepliesEnabled,
        mergeTags,
        getManagerQuickReplies,
        saveQuickReply,
        deleteQuickReply,
        handleAttachments,
        deleteAttachment,
        sendBatchMessage,
        openCarousel,
        allowCreateResident,
        currentUserId,
        validateEmail,
        openResidentStreamModal,
        timeService,
        cheatProofEngagementScoreEnabled,
        removeQueryStringParam,
        emailDomainBlocklist,
        isBulkCommunicationEnhancementEnabled,
        isAgentAttributionEnable
      }}
    >
      <Provider store={store}>
        <ThemeProvider>
          <Box className={classes.mainContainer}>
            <Residents />
          </Box>
        </ThemeProvider>
      </Provider>
    </AngularContextProvider>
  );
};

export default ResidentsPage;
