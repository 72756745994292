import React, { FC } from 'react';
import { ClawIcon, AnalyticsIcon } from '../../../icons';
import { UserProfileType } from '../TopNavBar';

interface AppMenuProps {
  openKnockAnalytics: () => void;
  openClawApp: () => void;
  isClawAvailable: boolean;
  hideAnalytics: boolean;
  isClawApplicantsPageAvailable: boolean;
  userProfile: UserProfileType;
}

export const AppMenu: FC<AppMenuProps> = ({
  openKnockAnalytics,
  openClawApp,
  isClawAvailable,
  hideAnalytics,
  isClawApplicantsPageAvailable,
  userProfile
}) => {
  //   const isMigrated = userProfile.is_migrated;

  return (
    <>
      {/* {isMigrated && (
        <omnibar-unified-switcher
          slot="switcher"
          onClick={(e: any) => {
            if (e) {
              e.stopPropagation();
            }
          }}
        ></omnibar-unified-switcher>
      )} */}
      {/* {!isMigrated && ( */}
      <omnibar-unified-switcher
        slot="switcher"
        onClick={(e: any) => {
          if (e) {
            e.stopPropagation();
          }
        }}
      >
        <div slot="switcher-content">
          <div className="o-unified-switcher__family-group">
            <div className="o-unified-switcher__family">
              KNOCK PRODUCT SUITE
            </div>
            <div className="o-unified-switcher__apps">
              {isClawAvailable && (
                <button
                  onClick={openClawApp}
                  className="o-unified-switcher__app"
                >
                  <span className="o-unified-switcher__icon">
                    <ClawIcon />
                  </span>
                  <span className="o-unified-switcher__name">
                    Centralized Leasing
                  </span>
                </button>
              )}
              {!hideAnalytics && (
                <button
                  onClick={openKnockAnalytics}
                  className="o-unified-switcher__app"
                >
                  <span className="o-unified-switcher__icon">
                    <AnalyticsIcon />
                  </span>
                  <span className="o-unified-switcher__name">
                    Insights & Analytics
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </omnibar-unified-switcher>
      {/* )} */}
    </>
  );
};
