import React from 'react';

export const SynchronizeArrowsNewIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89824 3.61325C7.88912 2.3932 6.22162 1.93624 4.72983 2.47093C3.23805 3.00562 2.2427 4.41701 2.24167 5.99912V7.02359M3.26667 8.57324C4.32351 9.69103 5.95654 10.0523 7.38757 9.48484C8.81859 8.9174 9.75808 7.53608 9.75833 5.99912V5.31705M9.75833 5.31705L11.125 6.68119M9.75833 5.31705L8.39167 6.68119M0.875 5.65809L2.24167 7.02222L3.60833 5.65809"
      stroke="#292B3D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
