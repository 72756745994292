import React, { FC } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';

interface VoiceAppCallProps {
  openKnockVoice: () => void;
  isVoiceAppStatusFeatureEnable: boolean;
  voiceAppStatus: string | undefined;
  focusOnKnockVoice: () => void;
}
const Container = styled('div')({
  position: 'relative'
});
const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 'calc(50% - 5px)',
    left: 'calc(50% + 10px)',
    transform: 'translate(-50%, -50%)',
    width: '8px',
    height: '8px',
    borderRadius: ({
      status,
      isVoiceAppStatusFeatureEnable
    }: {
      status: string | undefined;
      isVoiceAppStatusFeatureEnable: boolean;
    }) => {
      if (
        !isVoiceAppStatusFeatureEnable ||
        !status ||
        status === 'away' ||
        status === 'default'
      ) {
        return '0';
      }
      return '50%';
    },

    backgroundColor: ({
      status,
      isVoiceAppStatusFeatureEnable
    }: {
      status: string | undefined;
      isVoiceAppStatusFeatureEnable: boolean;
    }) => {
      if (!isVoiceAppStatusFeatureEnable) {
        return 'transparent';
      }
      if (!status) return 'transparent';
      switch (status) {
        case 'available':
          return '#088D61';
        case 'on_a_call':
          return '#EE223D';
        case 'away':
          return 'transparent';
        default:
          return 'transparent';
      }
    },
    outline: ({
      status,
      isVoiceAppStatusFeatureEnable
    }: {
      status: string | undefined;
      isVoiceAppStatusFeatureEnable: boolean;
    }) => {
      if (
        !isVoiceAppStatusFeatureEnable ||
        !status ||
        status === 'away' ||
        status === 'default'
      ) {
        return 'none';
      }
      return '2px solid white';
    },
    zIndex: 100
  }
});

const StatusIcon: FC<{
  status: string | undefined;
  isVoiceAppStatusFeatureEnable: boolean;
}> = ({ status, isVoiceAppStatusFeatureEnable }) => {
  const classes = useStyles({ status, isVoiceAppStatusFeatureEnable });
  return <div className={classes.root}></div>;
};

export const VoiceAppCall: FC<VoiceAppCallProps> = ({
  openKnockVoice,
  isVoiceAppStatusFeatureEnable,
  voiceAppStatus,
  focusOnKnockVoice
}) => {
  const openVoiceAppCall = () => {
    if (voiceAppStatus && voiceAppStatus !== 'on_a_call') {
      if (voiceAppStatus === 'available') {
        focusOnKnockVoice();
      } else {
        openKnockVoice();
      }
    }
  };

  return (
    <Container>
      <StatusIcon
        status={voiceAppStatus}
        isVoiceAppStatusFeatureEnable={isVoiceAppStatusFeatureEnable}
      />
      <omnibar-unified-menu-item
        data-testid="voice-app"
        menu-name="Open Voice App"
        icon="telephone-circle"
        kind="consumer"
        onClick={(e: any) => {
          e.stopPropagation();
          openVoiceAppCall();
        }}
      ></omnibar-unified-menu-item>
    </Container>
  );
};
